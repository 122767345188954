<template>
  <b-carousel-item>
    <section id="dsa" class="hero is-fullheight is-dark">
      <div class="hero-body slide-body has-text-black is-relative">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 823 684" style="enable-background:new 0 0 823 684;" xml:space="preserve" class="is-absolute">
<g id="dsa-layer">
	<g id="dsa-yellow-rect" class="animate__animated animate__fadeInDown _3">
		<rect x="330.77" y="28.53" class="black" width="287.78" height="146.8"/>
    <g>
			<rect x="319.76" y="20.82" class="yellow" width="285.78" height="144.8"/>
      <g>
				<path class="black"
              d="M604.54,21.82v142.8H320.76V21.82H604.54 M606.54,19.82H318.76v146.8h287.78V19.82L606.54,19.82z"/>
			</g>
		</g>
	</g>
  <g id="grid_1_" class="animate__animated animate__fadeIn _2 animate__slower">
		<g>
			<line class="red-stroke small-stroke" x1="237.21" y1="31.54" x2="558.68" y2="662.98"/>
      <line class="red-stroke small-stroke" x1="183.07" y1="59.11" x2="488.49" y2="659.05"/>
      <line class="red-stroke small-stroke" x1="128.92" y1="86.67" x2="426.22" y2="670.66"/>
      <line class="red-stroke small-stroke" x1="74.77" y1="114.24" x2="357.8" y2="670.18"/>
      <line class="red-stroke small-stroke" x1="20.62" y1="141.8" x2="290.46" y2="671.84"/>
      <line class="red-stroke small-stroke" x1="26.04" y1="286.38" x2="225.95" y2="679.05"/>
      <line class="red-stroke small-stroke" x1="53.42" y1="479.49" x2="137.67" y2="644.98"/>
		</g>
    <g>
			<line class="red-stroke small-stroke" x1="70.06" y1="164.14" x2="286.93" y2="53.73"/>
      <line class="red-stroke small-stroke" x1="38.34" y1="248.47" x2="314.5" y2="107.88"/>
      <line class="red-stroke small-stroke" x1="2.47" y1="334.91" x2="342.07" y2="162.02"/>
      <line class="red-stroke small-stroke" x1="30.04" y1="389.06" x2="369.63" y2="216.17"/>
      <line class="red-stroke small-stroke" x1="34.13" y1="455.15" x2="397.2" y2="270.32"/>
      <line class="red-stroke small-stroke" x1="32.4" y1="526.69" x2="425.77" y2="326.43"/>
      <line class="red-stroke small-stroke" x1="33.96" y1="594.08" x2="453.33" y2="380.58"/>
      <line class="red-stroke small-stroke" x1="32.59" y1="662.96" x2="480.9" y2="434.72"/>
      <line class="red-stroke small-stroke" x1="168.87" y1="661.76" x2="508.46" y2="488.87"/>
      <line class="red-stroke small-stroke" x1="300.25" y1="664.83" x2="536.75" y2="544.43"/>
      <line class="red-stroke small-stroke" x1="431.79" y1="668.74" x2="564.14" y2="601.36"/>
		</g>
	</g>
  <g id="dsa-triangle" class="animate__animated animate__fadeInLeft _3">
		<polygon class="black" points="409.25,518.61 283.77,341.19 23.85,669.69 		"/>
    <polygon class="blue black-stroke small-stroke" points="434.31,519.05 308.83,341.64 48.92,670.13 		"/>
	</g>
  <g id="dsa-circle" class="animate__animated animate__fadeInBottomRight _3">
		<circle class="black" cx="593.09" cy="456.38" r="215.24"/>
    <circle class="red black-stroke" cx="580.28" cy="441.73" r="215.24"/>
	</g>
  <g id="dsa-text" class="animate__animated animate__fadeIn _4">
		<rect x="302.13" y="89.82" class="black" width="394.2" height="495.8"/>
    <g>
			<rect x="283.43" y="71.3" class="white" width="390.2" height="491.8"/>
      <path class="black" d="M671.63,73.3v487.8h-386.2V73.3H671.63 M675.63,69.3h-394.2v495.8h394.2V69.3L675.63,69.3z"/>
		</g>
    <polygon class="no-fill"
             points="614.47,505.88 616.22,544.14 295.29,544.14 295.29,87.38 658.52,87.38 658.52,505.44 		"/>
    <text transform="matrix(1 0 0 1 295.2936 100.5577)"><tspan x="0" y="0" class="lufga svg-text-18">After moving to Denver in 2018, I became </tspan>
      <tspan x="0" y="21.6" class="lufga svg-text-18">active in the local chapter of Democratic </tspan>
      <tspan x="0" y="43.2" class="lufga svg-text-18">Socialists in the tech, agitprop, and </tspan>
      <tspan x="0" y="64.8" class="lufga svg-text-18">feminist committees.</tspan>
      <tspan x="0" y="108" class="lufga svg-text-18">The chapter’s website was previously built </tspan>
      <tspan x="0" y="129.6" class="lufga svg-text-18">and hosted on Wordpress, but that limited </tspan>
      <tspan x="0" y="151.2" class="lufga svg-text-18">our access to make certain elements </tspan>
      <tspan x="0" y="172.8" class="lufga svg-text-18">WCAG compliant. Since accessibility is a </tspan>
      <tspan x="0" y="194.4" class="lufga svg-text-18">priority for us, I rewrote </tspan>
      <tspan x="0" y="216" class="lufga svg-text-18">as a static HTML page to both maximize </tspan>
      <tspan x="0" y="237.6" class="lufga svg-text-18">that customization and likelihood of being </tspan>
      <tspan x="0" y="259.2" class="lufga svg-text-18">able to hand off in the future (rather than </tspan>
      <tspan x="0" y="280.8" class="lufga svg-text-18">frameworks that only a subset of people </tspan>
      <tspan x="0" y="302.4" class="lufga svg-text-18">would be familiar with).</tspan>
      <tspan x="0" y="345.6" class="lufga svg-text-18">While active in the chapter, I also </tspan>
      <tspan x="0" y="367.2" class="lufga svg-text-18">contributed several flyer and zine designs, </tspan>
      <tspan x="0" y="388.8" class="lufga svg-text-18">as well as integrating a store into the </tspan>
      <tspan x="0" y="410.4" class="lufga svg-text-18">website, and oversaw its launch with </tspan>
      <tspan x="0" y="432" class="lufga svg-text-18">my long sleeve skate tee design.</tspan></text>
    <a xlink:href="https://denverdsa.org" target="_blank" class="svg-link"><text transform="matrix(1 0 0 1 508.2216 294.9165)" class="lufga svg-text-18">DenverDSA.org</text></a>
	</g>
  <g id="circle_4_" class="animate__animated animate__fadeIn _5 ">
		<circle class="black" cx="521.54" cy="604.69" r="46.3"/>
    <circle class="blue black-stroke small-stroke" cx="514.75" cy="595.21" r="46.3"/>
	</g>
  <g id="Z_4_" class="animate__animated animate__fadeIn _5 ">
		<polygon class="black" points="709.4,383.33 735.3,370.16 772.73,387.01 760.76,423.72 799.4,437.77 773.5,450.06 732.67,436.89
			746.28,397.38 		"/>
    <polygon class="yellow black-stroke" points="706.35,376.75 732.26,363.58 769.69,380.43 757.72,417.14 796.35,431.19 770.45,443.48
			729.62,430.31 743.23,390.8 		"/>
	</g>
  <g id="circles2_3_" class="animate__animated animate__fadeIn _5 ">
		<g>
			<circle class="yellow black-stroke small-stroke" cx="413.59" cy="605.16" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="394.79" cy="605.16" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="375.98" cy="605.16" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="357.18" cy="605.16" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="338.37" cy="605.16" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="319.56" cy="605.16" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="300.76" cy="605.16" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="281.95" cy="605.16" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="263.15" cy="605.16" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="244.34" cy="605.16" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="225.53" cy="605.16" r="6.59"/>
		</g>
    <g>
			<circle class="yellow black-stroke small-stroke" cx="386.08" cy="635.45" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="367.27" cy="635.45" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="348.47" cy="635.45" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="329.66" cy="635.45" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="310.86" cy="635.45" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="292.05" cy="635.45" r="6.59"/>
		</g>
    <g>
			<circle class="yellow black-stroke small-stroke" cx="453.18" cy="665.74" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="434.38" cy="665.74" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="415.57" cy="665.74" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="396.76" cy="665.74" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="377.96" cy="665.74" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="359.15" cy="665.74" r="6.59"/>
		</g>
    <g>
			<circle class="yellow black-stroke small-stroke" cx="364.79" cy="574.86" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="345.98" cy="574.86" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="327.18" cy="574.86" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="308.37" cy="574.86" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="289.56" cy="574.86" r="6.59"/>
      <circle class="yellow black-stroke small-stroke" cx="270.76" cy="574.86" r="6.59"/>
		</g>
	</g>
  <g id="pika" class="animate__animated animate__fadeIn _6">
		<rect x="641.03" y="528.54" class="black" width="166.09" height="155.46"/>
    <g>
			<rect x="620.73" y="502.09" class="pure-white" width="164.09" height="153.46"/>
      <path class="black" d="M783.83,503.09v151.46l-162.09,0l0-151.46L783.83,503.09 M785.83,501.09l-166.09,0l0,155.46l166.09,0V501.09
				L785.83,501.09z"/>
		</g>
	</g>

  <image style="overflow:visible;" width="4309" height="4313" xlink:href="../assets/pika_logo.png"
         transform="matrix(0.0345 0 0 0.0345 628.4493 507.1275)" class="animate__animated animate__fadeIn _6">
	</image>
  <image style="overflow:visible;" width="1236" xlink:href="../assets/long-sleeve-mockup.png"
         transform="matrix(0.2065 0 0 0.2065 10.9906 25.7819)" class="animate__animated animate__fadeIn _6">
	</image>
  <image style="overflow:visible;" width="1036" xlink:href="../assets/prisonlaborposters.png"
         transform="matrix(0.2065 0 0 0.2065 35.9906 285.7819)" class="animate__animated animate__fadeIn _6">
	</image>
</g>
        </svg>
        <h1 class="title is-1 has-text-grey-dark is-highlighted red-highlight animate__animated animate__fadeInDown _2">dsa</h1>
      </div>
    </section>
  </b-carousel-item>
</template>

<script>
export default {
  name: "DSA"
}
</script>
